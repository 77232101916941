import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from "web3"

const wait = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms))

const checkMetamask = async () => {
    let provider = await detectEthereumProvider({ mustBeMetaMask: true })
    if (provider) {
        window.ethereum = provider
        window.web3 = new Web3(provider);
        return true
    }
    return false
}

const handleSignMessage = async ({ publicAddress, nonce }) => {
    try {
        let message = 'Hi there from the Photochromic members community. Please sign this message to prove that you have access to this wallet. This will not cost you any ether. Your unique ID is: ' + nonce
        // let hash = window.web3.utils.sha3(message)
        return await window.web3.eth.personal.sign(message, publicAddress)

    } catch (e) {
        // alert(e)
        // if (onErr) onErr(e)
        return false
    }


}

const connect = async (onErr) => {
    try {
        let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        window.connected = accounts.length > 0 && window.ethereum.isConnected
        window.web3 = new Web3(window.ethereum);
    } catch (e) {
        if (onErr) onErr(e)
        return false
    }
}

const connected = async () => {
    if (!window.ethereum || !window.ethereum.isConnected) return false
    let metamask = await checkMetamask()
    if (!metamask) return false
    let accs = await window.web3.eth.getAccounts();
    if (accs.length > 0) return true
    return false
}

const getAccount = async () => {
    let isConnected = await connected();
    if (isConnected) {
        let accs = await window.web3.eth.getAccounts();
        let full = accs[0];
        let short = '';
        if (full) {
            let prefix = full.substr(0, 5)
            let suffix = full.substr((full.length - 4), 4)
            short = prefix + '...' + suffix;
        }

        return { full, short }
    }
    return null;
}


const connection = {
    checkMetamask, connect, connected, getAccount, wait, handleSignMessage
}

export default connection