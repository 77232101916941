<template>
  <slot></slot>
</template>
<script>
export default {
    props: {},
    setup()
    {

    }
}
</script>