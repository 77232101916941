<template>
  <div v-if="!showForm">
    <div class="relative z-10 md:my-12 md:mx-5">
      <div class="relative z-20 container max-w-6xl mx-auto pt-10">
        <div class="connect_content bg-cover bg-center bg-no-repeat relative md:rounded-md px-8 py-7 lg:px-12 lg:py-12">
          <div class="flex flex-wrap md:-ml-5 md:-mr-5">
            <div class="">
              <div class="color-black-1 text-lg lg:text-xl color-light-black leading-relaxed mb-5 wow fadeInUp" data-wow-delay="0.3s">The PhotoChromic project is intimately linked to each person’s claim to their own identity and ability to take control over that important asset.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.4s">Verifying your identity on a platform through validation and then interacting with that community is a form of influence.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.5s">We will seek to scale our project through the verified identities of influencers who believe in the self-sovereignty of their personal brand value, being able to use their NFTIDs for real world applications.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.6s">Project partners such as document signature companies, vertical markets for collectables and key artists, sportspeople and celebrities would participate in the early stages of the launch.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showForm">
    <div class="relative z-10 md:my-12 md:mx-5">
      <div class="relative z-20 container max-w-6xl mx-auto pt-10">
        <div class="connect_content bg-cover bg-center bg-no-repeat relative md:rounded-md md:px-8 md:py-7 lg:px-12 lg:py-12">
          <div class="flex flex-wrap md:-ml-5 md:-mr-5">
            <div class="md:w-2/4 px-6 order-2 md:order-1 mb-8 md:mb-0">
              <div class="color-black-1 text-3xl md:text-4xl lg:text-5xl text-center mb-6 font-ubuntu font-medium wow fadeInUp" data-wow-delay="0.2s">Connect with us</div>
              <div class="color-black-1 text-lg lg:text-xl color-light-black leading-relaxed mb-5 wow fadeInUp" data-wow-delay="0.3s">The PhotoChromic project is intimately linked to each person’s claim to their own identity and ability to take control over that important asset.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.4s">Verifying your identity on a platform through validation and then interacting with that community is a form of influence.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.5s">We will seek to scale our project through the verified identities of influencers who believe in the self-sovereignty of their personal brand value, being able to use their NFTIDs for real world applications.</div>
              <div class="text-md color-light-black mb-5 wow fadeInUp" data-wow-delay="0.6s">Project partners such as document signature companies, vertical markets for collectables and key artists, sportspeople and celebrities would participate in the early stages of the launch.</div>
            </div>
            <div class="w-full md:w-2/4 md:px-5 order-1 md:order-2 ml-auto mr-auto">
              <div class="get_in_block md:rounded-md lg:-mt-28 px-6 py-10 md:py-8 lg:py-14 md:px-8 lg:px-14 mb-8 md:mb-0 wow bounceInUp" data-wow-delay="0.6s">
                <div class="text-center">
                  <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl wow fadeInUp" data-wow-delay="0.2s">
                    Get In Touch
                  </h2>
                </div>
                <div class="mt-10">
                  <form action="#" method="POST" class="form-main">
                    <div class="mt-3 wow fadeInUp" data-wow-delay="0.2s">
                      <input type="text" name="first-name" placeholder="Full Name" class="py-6 px-5 block w-full rounded-md text-base leading-6 h-14 font-medium mb-5 text-white rounded-md placeholder-white border-white focus:border-white">
                    </div>
                    <div class="mt-3 wow fadeInUp" data-wow-delay="0.3s">
                      <input type="email" name="email" placeholder="Email" class="py-6 px-5 block w-full rounded-md text-base leading-6 h-14 font-medium mb-5 text-white rounded-md placeholder-white border-white focus:border-white">
                    </div>
                    <div class="mt-3 wow fadeInUp" data-wow-delay="0.4s">
                      <textarea name="message" rows="4" placeholder="Message" class="py-4 px-5 block w-full rounded-md text-base leading-6 h-24 font-medium mb-7 text-white rounded-md placeholder-white border-white focus:border-white"></textarea>
                    </div>
                    <div class="mb-7 wow fadeInUp" data-wow-delay="0.5s">
                      <label>
                        <input type="checkbox">
                        <span class="text_checkbox">By sending this form I accept Terms of Use</span>
                      </label>
                    </div>
                    <div class="mt-3 wow fadeInUp" data-wow-delay="0.6s">
                      <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-xl font-medium text-indigo-600 bg-white hover:bg-indigo-700 hover:text-white focus:outline-none hover:shadow-md h-14 font-ubuntu">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  props: {},
  setup()
  {
    const showForm = ref(false)


    return { showForm }
  }
}
</script>