<template>
  <TheLayout>
    <TheContent>
      <section class="relative z-10 identity_block bg-white pt-10 pb-10 md:pt-14 md:pb-12 lg:pt-28 lg:pb-24 px-5">
        <div class="relative z-20 container max-w-3xl mx-auto">
          <div>
            <div class="text-center">
              <div id="your-identity" class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 wow fadeInUp" data-wow-delay="0.2s">
                Legal Notice
              </div>
            </div>
          </div>
        </div>
        <div class="relative z-20 container max-w-6xl mx-auto">
          <p>Please read these terms of use carefully before using the Genesis Edition PhotoSapiens Non-Fungible Token (“<strong>NFT</strong>”) minting service (the “<strong>Service</strong>”) provided by PhotoChromic.
          </p>
          <p>Your access to, and use of, the Service is conditional upon your acceptance of, and compliance with, these terms. By using or accessing the Service, you are agreeing to these terms and agreeing to be bound by these terms. If you disagree with any part of the terms, then you do not have permission to access and use the Service.</p>
          <h3>Payment of the Minting Fee</h3>
          <p>The fee that is charged by PhotoChromic (“<strong>Minting Fee</strong>”) is payable by you so that you can utilise the Service to mint your own Genesis Edition PhotoSapiens NFT. The NFT is your own work product, you are not purchasing the NFT from PhotoChromic. PhotoChromic has not made any representation, warranty, or assurance in respect of the value of your Genesis Edition PhotoSapiens NFT or any other PhotoSapiens NFT. You understand and acknowledge that there is no assurance that you will be able to sell your Genesis Edition PhotoSapiens NFT to recover the Minting Fee that you paid to utilise the Service.
          </p>
          <h3>Use of the Service</h3>
          <p>Your use of the Service is at your own risk. You acknowledge and agree that you have undertaken your own analysis of the Service and of NFTs and you have read and understood the nature and purpose of the Service and the work product you will create when you utilise the Service, the Genesis Edition PhotoSapiens NFT.</p>
          <p>By uploading an image onto the Service, you represent and warrant that you own the image or have the right to use the image with the Service and to use the image to mint a Genesis Edition PhotoSapiens NFT. You also represent and warrant that the uploading of the image does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.</p>
          <p>You retain any and all rights in the image you upload and in the PhotoSapiens NFT that you create through your usage of the Service. You bear the sole responsibility of protecting those rights. PhotoChromic takes no responsibility and assumes no liability for any image you or any third-party uploads to the Service.</p>
          <p>You retain full control of your personal and biometric data at all times during the use of the Service. No personal or biometric data, including any image uploaded by you, is captured, stored, processed, or retained by PhotoChromic during the minting of a Genesis Edition PhotoSapiens NFT. At no time is PhotoChromic a controller or processor of any personal or biometric data.</p>
          <h3>Limitation of Liability</h3>
          <p>Iin no event shall PhotoChromic, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), equity or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
          <h3>Disclaimer</h3>
          <p>Your use of the Service is at your sole risk. The Service is provided on an "as is" and "as available" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. PhotoChromic, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</p>
          <h3>Governing Law</h3>
          <p>These terms will be governed by and construed in accordance with English law. Any failure by PhotoChromic to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is for any reason held to be invalid, illegal or unenforceable, in whole or in part the remaining provisions of these terms will remain in effect. These terms constitute the entire agreement between us regarding the Service and supersedes and extinguishes all other agreements, promises, assurances, warranties, representations and understandings between us, whether written or oral, relating to the Service.</p>
        </div>
      </section>
    </TheContent>
    <TheFooter></TheFooter>
  </TheLayout>
</template>
<script>
import TheLayout from "@/components/TheLayout";
import TheFooter from "@/components/TheFooter";
import TheContent from "@/components/TheContent";
import { onMounted, inject, ref } from "vue";
import WOW from "wow.js";
import connection from '../utils/crypto/connection'

const emitter = require('tiny-emitter/instance');


export default {
  components: { TheContent, TheFooter, TheLayout },
  props: {},
  setup()
  {

    const router = inject('router')
    const triggerConnect = inject('triggerConnect')
    const headerState = inject('headerState', 'connect')
    const hasMetaMask = ref(false)

    async function isConnected()
    {
      //Is connected?
      let connectedAccount = await connection.getAccount();
      if (connectedAccount) {
        emitter.emit('fire-alert', 'Connected', 'success')
        return router.push({ name: 'step-2' })
      }
    }

    async function connect()
    {
      await triggerConnect()
      await isConnected();
    }


    onMounted(async () => {
      let wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true,
        scrollContainer: null,
      });
      wow.init();

      hasMetaMask.value = await connection.checkMetamask()

    })

    return { connect, hasMetaMask, headerState }
  }


}
</script>
<style scoped>
h3 {
  @apply text-4xl py-5;
}

p {
  @apply pb-2;
}
</style>