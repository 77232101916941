<template>
  <footer class="relative footer pt-12 md:pt-48 pb-8 md:-mt-48">
    <div class="container max-w-6xl mx-auto px-6 xl:px-0">
      <div class="pt-3 pb-12 border-gray-500 border-solid border-b-2">
        <div class="max-w-4xl mx-auto">
          <img class="" src="../assets/images/png/quotes.png" alt="">
          <div class="text-white text-xl md:text-2xl pl-10 font-ubuntu font-light">The things people own are inherently part of their identity. The ability to be able to use the blockchain to bond one’s identity to a physical or digital asset is a key component of a decentralized world.</div>
        </div>
      </div>
      <div class="flex flex-wrap lg:grid lg:grid-cols-2 lg:gap-8 pt-6 md:pt-12 lg:pt-20">
        <div class="w-full md:grid md:grid-cols-1 md:gap-8 order-2 lg:order-1">
          <div class="mt-6 md:mt-8 lg:mt-0 lg:pr-6 wow fadeInUp" data-wow-delay="0.2s">
            <div class="max-w-md">
              <div class="text-base leading-loose text-gray-400 mb-8 wow fadeInUp" data-wow-delay="0.4s">
                PhotoChromic is a framework for creating and managing your NFT identity on blockchain networks, software services and DApps.
              </div>
            </div>
            <div class="text-center md:text-left">
              <div class="text-center md:text-left mb-8 md:mb-12 lg:mb-16 inline-flex items-center gap-4">
                <a href="https://twitter.com/photo_chromic" class="soc_img" target="_blank">
                  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path class="st_1"
                          d="M20 1.92187C19.2578 2.25 18.4648 2.46484 17.6367 2.57031C18.4883 2.0625 19.1367 1.26562 19.4414 0.304687C18.6484 0.777343 17.7734 1.10937 16.8398 1.29687C16.0898 0.496093 15.0156 0 13.8477 0C11.5781 0 9.75 1.84375 9.75 4.10156C9.75 4.42577 9.77734 4.73827 9.84375 5.03515C6.43359 4.87109 3.41797 3.23437 1.39062 0.746093C1.03516 1.35937 0.828125 2.0625 0.828125 2.82031C0.828125 4.24218 1.55859 5.49999 2.64844 6.22655C1.98828 6.21483 1.34375 6.02343 0.796875 5.72265C0.796875 5.73437 0.796875 5.74999 0.796875 5.76952C0.796875 7.76171 2.21875 9.41795 4.08203 9.79686C3.75 9.8867 3.38281 9.93358 3.00781 9.93358C2.74609 9.93358 2.48047 9.91795 2.23047 9.86327C2.76172 11.4883 4.26953 12.6797 6.0625 12.7187C4.66797 13.8086 2.89453 14.4687 0.980469 14.4687C0.644531 14.4687 0.320312 14.4531 0 14.414C1.81641 15.5859 3.96875 16.2539 6.28906 16.2539C13.8359 16.2539 17.9609 10.0039 17.9609 4.58593C17.9609 4.40624 17.9531 4.23046 17.9453 4.05468C18.7578 3.47656 19.4414 2.7539 20 1.92187Z"/>
                    </g>
                  </svg>
                </a>
                <a href="https://medium.com/photochromic" class="soc_img" target="_blank">
                  <svg width="24px" height="24px" viewBox="0 0 256 256" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
                    <g>
                      <rect class="st_1" x="0" y="0" width="256" height="256"/>
                      <path fill="black"
                          d="M61.0908952,85.6165814 C61.3045665,83.5054371 60.4994954,81.4188058 58.9230865,79.9979257 L42.8652446,60.6536969 L42.8652446,57.7641026 L92.7248438,57.7641026 L131.263664,142.284737 L165.145712,57.7641026 L212.676923,57.7641026 L212.676923,60.6536969 L198.947468,73.8174045 C197.763839,74.719636 197.176698,76.2025173 197.421974,77.670197 L197.421974,174.391342 C197.176698,175.859021 197.763839,177.341902 198.947468,178.244134 L212.355766,191.407842 L212.355766,194.297436 L144.91283,194.297436 L144.91283,191.407842 L158.802864,177.923068 C160.16778,176.558537 160.16778,176.157205 160.16778,174.070276 L160.16778,95.8906948 L121.54867,193.97637 L116.329871,193.97637 L71.3679139,95.8906948 L71.3679139,161.628966 C70.9930375,164.392788 71.9109513,167.175352 73.8568795,169.174019 L91.9219516,191.086776 L91.9219516,193.97637 L40.6974359,193.97637 L40.6974359,191.086776 L58.7625081,169.174019 C60.6942682,167.172038 61.5586577,164.371016 61.0908952,161.628966 L61.0908952,85.6165814 Z"/>
                    </g>
                  </svg>
                </a>
                <a href="https://t.me/photo_chromic" class="soc_img" target="_blank">
                  <svg width="24px" height="24px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink" space="preserve"
                      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;">
                    <path class="st_1"
                        d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"/>
                  </svg>
                </a>
              </div>
            </div>
            <div class="text-center md:text-left text-sm leading-relaxed text-gray-400 wow fadeInUp" data-wow-delay="0.4s">
              © {{ new Date().getFullYear() }} PhotoChromic. All rights reserved <br class="block md:inline-block"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>