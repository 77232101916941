<template>
  <div class="banner" :class="{'banner-hide': !showBanner}">
    <div class="flex items-center">
      <div class="mr-12">
        By using Photosapiens’ services you agree to our Cookies Use. We and our partners operate globally and use cookies for analytics only.
      </div>
      <div>
        <button class="btn btn-secondary" @click="clickHide">Ok</button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "vue";

export default {
  props: {},
  setup()
  {

    const showBanner = ref(true)


    const returnCookieStatus = computed(() => {
      let cookieState = localStorage.getItem('cookie-notification')
      if (cookieState) {
        return cookieState === 'true';
      }
      localStorage.setItem('cookie-notification', 'true')
      return true;
    })

    function clickHide()
    {
      localStorage.setItem('cookie-notification', 'false')
      showBanner.value = false;
    }

    onMounted(() => {
      showBanner.value = returnCookieStatus.value;
    })

    return { clickHide, showBanner }

  }
}
</script>
<style scoped>
.banner {
  @apply z-50 fixed bottom-0 left-0 w-full h-12 bg-primary-600 text-white flex items-center justify-center transition-all;
}

.banner-hide {
  @apply -bottom-20;
}
</style>