<template>
  <div class="relative top-block pb-2">
    <div class="relative">
      <header>
        <div class="relative bg-transparent">
          <div class="flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div class="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <span class="sr-only">Logo</span>
                <img class="w-auto shadow-white rounded-b-4xl" src="../assets/images/png/logo.png" alt="">
              </a>
            </div>
            <div class="mr-1 -my-2 md:hidden">
              <button @click="showMobile = true" type="button" class="shadow-white bg-white bg-app-gradient rounded-md p-2 inline-flex items-center justify-center text-white hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                <span class="sr-only">Open menu</span>
                <MenuIcon class="h-6 w-6"></MenuIcon>
              </button>
            </div>
            <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a href="https://twitter.com/photo_chromic" target="_blank" class="text-white px-3">
                <IconTwitter dim-class="h-8 w-8"></IconTwitter>
              </a>
              <a href="https://t.me/photo_chromic" target="_blank" class="px-3 text-white">
                <IconTelegram dim-class="h-8 w-8"></IconTelegram>
              </a>
              <a href="https://photochromic.io/pdf/PhotoChromic-Whitepaper.pdf" target="_blank" class="text-white text-center hover:text-primary-600 font-ubuntu px-3">
                Download The <br/>
                Whitepaper
              </a>
              <TheWalletConnect></TheWalletConnect>
            </div>
          </div>
          <!-- Mobile menu -->
          <div v-show="showMobile" class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-app-gradient">
              <div class="pb-2 px-5">
                <div class="flex items-center justify-between">
                  <div class="bg-black rounded-b-2xl">
                    <img class="w-32" src="../assets/images/png/logo.png" alt="">
                  </div>
                  <div class="pt-3 -mr-2 ml-auto">
                    <button @click="showMobile = false" type="button" class="bg-black bg-opacity-75 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-primary-600 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6"></XIcon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="py-3 px-5 text-center">
                <div class="p-3">
                  <a href="https://twitter.com/photo_chromic" target="_blank" class="inline-block social-link px-3 text-white">
                    <IconTwitter dim-class="h-8 w-8"></IconTwitter>
                  </a>
                  <a href="https://t.me/photo_chromic" target="_blank" class="inline-block social-link px-3 text-white">
                    <IconTelegram dim-class="h-8 w-8"></IconTelegram>
                  </a>
                </div>
                <div class="p-3">
                  <a href="https://photochromic.io/pdf/PhotoChromic-Whitepaper.pdf" target="_blank" class=" text-center text-white font-ubuntu">
                    Download The <br/>
                    Whitepaper
                  </a>
                </div>
                <div class="p-3">
                  <TheWalletConnect></TheWalletConnect>
                </div>
              </div>
            </div>
          </div>
          <!-- /Mobile menu -->
        </div>
      </header>
      <div class="flex items-center justify-center overflow-hidden">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import IconTwitter from "./icons/IconTwitter.vue";
import IconTelegram from "./icons/IconTelegram.vue";
import TheWalletConnect from "./TheWalletConnect.vue";

export default {
  props: {},
  components: {
    TheWalletConnect,
    IconTelegram,
    IconTwitter,
    MenuIcon, XIcon
  },
  setup()
  {
    const showMobile = ref(false)

    return { showMobile }
  }
}
</script>