import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/views/Main';
import Terms from '@/views/Terms';
import ViaShare from '@/views/ViaShare.vue';

const routes = [
    { path: '/', component: Main, name: 'main' },
    { path: '/terms', component: Terms, name: 'terms' },
    {
        path: "/:catchAll(.*)",
        component: ViaShare,
        name: "ViaShare",
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})


export default router;