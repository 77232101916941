import axios from 'axios'


let result = {
    success: false,
    code: 200,
    meta: 'OK',
    data: {}
};

export default {


    initSession(account)
    {
        return axios.post('/token-init', { account })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },
}
