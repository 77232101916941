<template>
  <main>
    <slot></slot>
  </main>
</template>
<script>

export default {
  props: {},
    setup()
    {

    }
}
</script>