<template>
  <TheLayout>
    <TheHeader>
      <div class="relative px-4 md:mt-12 xl:mt-16">
        <HeaderMint v-if="headerState === 'mint'"></HeaderMint>
        <HeaderConnect v-if="headerState === 'connect'"></HeaderConnect>
        <HeaderJoin v-if="headerState === 'join'"></HeaderJoin>
        <HeaderShare v-if="headerState === 'share'"></HeaderShare>
        <div class="text-center pt-6">
          <a href="#your-identity">
            <img class="more_detail inline-block cursor-pointer" src="../assets/images/png/more_detail.png" alt=""/>
          </a>
        </div>
      </div>
    </TheHeader>
    <TheContent>
      <section class="relative z-10 identity_block bg-white pt-10 pb-10 md:pt-14 md:pb-12 lg:pt-28 lg:pb-24 px-5">
        <div class="wrapper_ellipse container mx-auto hidden lg:block">
          <img class="ellipse_img ellipse_img_1" src="../assets/images/png/ellipse_1.png" alt=""/>
        </div>
        <div class="relative z-20 container max-w-3xl mx-auto">
          <div>
            <div class="text-center">
              <div id="your-identity" class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 wow fadeInUp" data-wow-delay="0.2s">
                Create Your <span class="bg_text bg_text_1">Identity</span> On The Blockchain
              </div>
              <div class="text-lg md:text-xl color-light-black wow fadeInUp" data-wow-delay="0.4s">Use your face to co-create a unique piece of generative art as the visual layer of your NFT. This first generation of PhotoSapiens don’t offer the biometric verification, but collectively steer the direction of the PhotoChromic project.</div>
            </div>
          </div>
        </div>
        <div class="relative z-20 container max-w-6xl mx-auto">
          <div class="mt-12 mb-12 mx-auto max-w-md grid gap-8 sm:max-w-lg lg:grid-cols-3 lg:max-w-7xl">
            <div class="wrapper_border_1 flex flex-col rounded-lg shadow-lg overflow-hidden wow fadeInUp justify-between" data-wow-delay="0.4s">
              <div class="h-56 w-full bg-contain bg-no-repeat bg-center bg-origin-content py-4 " :style="{'background-image': 'url(' + require('@/assets/images/png/ntf_collections.png') + ')'}"></div>
              <div class="p-10">
                <p class=" text-white text-center font-medium text-xl  font-medium">A unique collection of 10,000 NFTs</p>
              </div>
            </div>
            <div class="wrapper_border_1 flex flex-col rounded-lg shadow-lg overflow-hidden wow fadeInUp justify-between" data-wow-delay="0.4s">
              <div class="h-56 w-full bg-contain bg-no-repeat bg-center bg-origin-content py-4 " :style="{'background-image': 'url(' + require('@/assets/images/png/vote.png') + ')'}"></div>
              <div class="p-10">
                <p class=" text-white text-center font-medium text-xl  font-medium">Vote on the direction of PhotoChromic</p>
              </div>
            </div>
            <div class="wrapper_border_1 flex flex-col rounded-lg shadow-lg overflow-hidden wow fadeInUp justify-between" data-wow-delay="0.4s">
              <div class="h-56 w-full bg-contain bg-no-repeat bg-center bg-origin-content py-4 " :style="{'background-image': 'url(' + require('@/assets/images/png/wagmi.png') + ')'}"></div>
              <div class="p-10">
                <p class=" text-white text-center font-medium text-xl  font-medium">Be an early adopter of the long term vision</p>
              </div>
            </div>
          </div>
        </div>
        <div class="relative z-10 container max-w-6xl mx-auto md:mt-32">
          <div class="text-center">
            <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 wow fadeInUp flex justify-center flex-wrap gap-2" data-wow-delay="0.2s">
              <span class="bg_text bg_text_1">HomoSapien</span><span class="">to</span><span>PhotoSapien</span>
            </div>
            <div class="md:px-12 wow fadeInUp" data-wow-delay="0.2s">
              <img class="object-cover" src="../assets/images/png/homo_to_photo.png" alt=""/>
            </div>
          </div>
          <div class="mt-6 -ml-5 -mr-5 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-4 lg:mt-8 lg:col-span-2">
            <div class="pl-5 pr-5 wow fadeInUp" data-wow-delay="0.2s">
              <h3 class="text-2xl font-ubuntu mb-4">The traditional identity you know</h3>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                Governed by boundaries of the physical world.
              </p>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                Identities managed by centralised governments and services.
              </p>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                At risk of exposing data to unknown third parties.
              </p>
            </div>
            <div class="pl-5 pr-5 wow fadeInUp" data-wow-delay="0.2s">
              <h3 class="text-2xl font-ubuntu mb-4">The identity you’ll create</h3>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                Be unidentifiable on chain, still having ownership of your identity.
              </p>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                Navigate the Web 3.0 metaverse in the comfort of your chosen avatar.
              </p>
              <p class="mt-2 flex items-start text-lg md:text-xl color-light-black">
                <img class="flex-shrink-0 mr-2 -mt-1" src="../assets/images/svg/check_1.svg" alt=""/>
                Manage your identity in the form of an NFT.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative z-10 community_block py-10 md:py-14 lg:py-28 bg-cover bg-center bg-no-repeat">
        <div class="container max-w-5xl mx-auto">
          <div class="text-center">
            <div class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-9">
              <span class="bg_text bg_text_4 wow fadeInUp mr-4" data-wow-delay="0.2s">10,000</span>
              <span class="text-white wow fadeInUp" data-wow-delay="0.4s">Community Members Decide On The Future of PhotoChromic's Visual Direction</span>
            </div>
          </div>
        </div>
        <div class="container max-w-6xl mx-auto">
          <div class="relative mt-6 mx-6 md-mx-0 lg:mt-8 md:grid md:grid-cols-2 md:gap-8 md:items-center">
            <div class="pb-12 md:pb-0 md:pr-5 wow fadeInLeft" data-wow-delay="0.4s">
              <img class="flex-shrink-0 md:mr-2" src="../assets/images/png/nft.png" alt=""/>
            </div>
            <div class="md:pb-5 wow fadeInRight" data-wow-delay="0.4s">
              <p class="mb-12 flex items-start text-lg md:text-xl color-light-black wow fadeInUp" data-wow-delay="0.2s">
                <img class="flex-shrink-0 mr-5" src="../assets/images/svg/punkt_1.svg" alt=""/>
                <span class="text-white">The first mint creates a founding community DAO and aggregates the choice of generative art choices.</span>
              </p>
              <p class="mb-12 flex items-start text-lg md:text-xl color-light-black wow fadeInUp" data-wow-delay="0.2s">
                <img class="flex-shrink-0 mr-5" src="../assets/images/svg/punkt_2.svg" alt=""/>
                <span class="text-white">When the biometric PhotoChromic product deploys, the design will be guided by the DAO.</span>
              </p>
              <p class="flex items-start text-lg md:text-xl color-light-black wow fadeInUp" data-wow-delay="0.2s">
                <img class="flex-shrink-0 mr-5" src="../assets/images/svg/punkt_3.svg" alt=""/>
                <span class="text-white">A collection of 10,000 global citizens open up the future of identity for Web 3.0.</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative pt-10 md:pt-14 lg:pt-28 bg-white">
        <div class="wrapper_ellipse wrapper_ellipse_2 hidden lg:block">
          <img class="ellipse_img ellipse_img_2" src="../assets/images/png/ellipse_16.png" alt=""/>
        </div>
        <div class="wrapper_ellipse wrapper_ellipse_3 hidden lg:block">
          <img class="ellipse_img ellipse_img_3" src="../assets/images/png/ellipse_3.png" alt=""/>
        </div>
        <div class="wrapper_ellipse wrapper_ellipse_4 hidden lg:block">
          <img class="ellipse_img ellipse_img_4" src="../assets/images/png/ellipse_16.png" alt=""/>
        </div>
        <div class="wrapper_ellipse wrapper_ellipse_5 hidden lg:block">
          <img class="ellipse_img ellipse_img_5 h-96" src="../assets/images/png/ellipse_4.png" alt=""/>
        </div>
        <div class="relative z-10 container max-w-5xl mx-auto pb-10 md:pb-14 lg:pb-24">
          <div class="text-center pl-5 pr-5 lg:pl-0 lg:pr-0">
            <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-7 wow fadeInUp" data-wow-delay="0.2s">
              Participate In a Historical Event
              For Humanity On <span class="bg_text bg_text_5">Web 3.0</span>
            </div>
            <div class="py-8 md:px-5 wow fadeInUp" data-wow-delay="0.2s">
              <img class="ml-auto mr-auto" src="../assets/images/svg/photosapien.svg" alt=""/>
            </div>
            <div class="pt-9 wow fadeInUp" data-wow-delay="0.2s" v-if="headerState === 'connect'">
              <a href="#" class="btn-content">Register to Join The Waitlist</a>
            </div>
          </div>
        </div>
        <div class="relative z-10 container max-w-6xl mx-auto pb-0 md:pb-12 pt-12">
          <div class="text-center">
            <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 pl-5 pr-5 lg:pl-0 lg:pr-0 wow fadeInUp flex flex-wrap justify-center" data-wow-delay="0.2s">
              <span>Co-Create Your NFT With The</span>
              <span class="bg_text bg_text_6">Photochromic Algorithm</span>
            </div>
            <div class="py-6 px-5 md:px-0 wow fadeInUp" data-wow-delay="0.4s">
              <img class="ml-auto mr-auto" src="../assets/images/svg/algorithm.svg" alt=""/>
            </div>
          </div>
        </div>
        <div class="relative z-10 container max-w-3xl mx-auto pt-10">
          <div class="text-center">
            <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 pl-5 pr-5 lg:pl-0 lg:pr-0 wow fadeInUp" data-wow-delay="0.2s">
              Become a Gen 1
              <span class="bg_text bg_text_7">PhotoSapien</span>
            </div>
            <div class="text-xl color-light-black pl-5 pr-5 lg:pl-0 lg:pr-0 wow fadeInUp" data-wow-delay="0.2s">Co-Create a unique Gen 1 Photosapien NFT that you can keep for yourself or sell on the secondary market.</div>
          </div>
        </div>
        <div class="relative z-10 pb-3 md:px-5 wow fadeInUp" data-wow-delay="0.2s">
          <img class="ml-auto mr-auto" src="../assets/images/png/users.png" alt=""/>
        </div>
        <div class="relative z-10 container max-w-3xl mx-auto pt-10" id="how-to-mint">
          <div class="text-center">
            <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 pl-5 pr-5 lg:pl-0 lg:pr-0 wow fadeInUp" data-wow-delay="0.2s">
              How to mint your Genesis Collection
              <span class="bg_text bg_text_7">PhotoSapien</span>
            </div>
            <div class="text-xl text-left color-light-black pl-5 pr-5 lg:pl-0 lg:pr-0 wow fadeInUp" data-wow-delay="0.2s">
              <ol class="how-to-mint">
                <li>Come back to this site (<span class="bg_text_7">https://photosapiens.io</span>)</li>
                <li>Connect your MetaMask Wallet</li>
                <li>Upload a photograph of yourself — a head and shoulders</li>
                <li>Adjust the texture, colour and identifiability of your image</li>
                <li>Mint your PhotoSapien by confirming the transaction in your wallet</li>
                <li>It’s an ERC-721, Ethereum NFT, so you can keep it in your wallet, transfer it elsewhere, or even list it for sale.</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="relative pt-20 z-10 md:mb-8 md:mb-12 px-5">
          <div class="container max-w-6xl mx-auto md:pb-12">
            <div class="mt-6 -ml-5 -mr-5 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-4 lg:mt-8 lg:col-span-2">
              <div class="px-5 wow fadeInLeft" data-wow-delay="0.6s">
                <div class="">
                  <div class="color-black-1 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-ubuntu font-medium mb-6 md:mb-9 wow fadeInUp" data-wow-delay="0.4s">
                    <span class="bg_text bg_text_8">Foundation</span>
                    For The Internet Of People
                  </div>
                  <div class="color-black-1 text-lg md:text-xl color-light-black leading-loose wow fadeInUp" data-wow-delay="0.6s">PhotoChromic is a blockchain protocol that gives ownership and control of the identity of a person back to the individual, and enables valuable utility on the internet to bond that identity to valuable assets, relationships and agreements.</div>
                </div>
              </div>
              <div class="px-5 text-center wow fadeInRight" data-wow-delay="0.4s">
                <img class="w-60 md:w-auto ml-auto mr-auto" src="../assets/images/svg/people.svg" alt="">
              </div>
            </div>
            <div class="mt-6 -ml-5 -mr-5 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-4 lg:mt-12 lg:col-span-2">
              <div class="px-5">
                <div class="mb-10 flex items-start wow fadeInUp" data-wow-delay="0.2s">
                  <img class="flex-shrink-0 mr-5" src="../assets/images/svg/foundation_icon_1.svg" alt="">
                  <div>
                    <div class="color-black-1 text-xl font-semibold color-light-black mb-5">Know Your Customer</div>
                    <div class="text-md color-light-black">Know your customer involves checking that a person is real, and that their documents haven’t been forged. Typically this involves cross checking a government-issued ID and proof of address.</div>
                  </div>
                </div>
                <div class="mb-10 flex items-start wow fadeInUp" data-wow-delay="0.4s">
                  <img class="flex-shrink-0 mr-5" src="../assets/images/svg/foundation_icon_3.svg" alt="">
                  <div>
                    <div class="color-black-1 text-xl font-semibold color-light-black mb-5">NFT (Non fungible tokens)</div>
                    <div class="text-md color-light-black">provides the core components to empower society to manage their identity and navigate the use of it in the new borderless economy.</div>
                  </div>
                </div>
                <div class="mb-10 flex items-start wow fadeInUp" data-wow-delay="0.6s">
                  <img class="flex-shrink-0 mr-5" src="../assets/images/svg/foundation_icon_5.svg" alt="">
                  <div>
                    <div class="color-black-1 text-xl font-semibold color-light-black mb-5">A network of identity validation nodes</div>
                    <div class="text-md color-light-black">Over time, people that you trust will take on the role of validators of the protocol, and earn tokens for their efforts.</div>
                  </div>
                </div>
              </div>
              <div class="px-5">
                <div class="mb-10 flex items-start wow fadeInUp" data-wow-delay="0.2s">
                  <img class="flex-shrink-0 mr-5" src="../assets/images/svg/foundation_icon_2.svg" alt="">
                  <div>
                    <div class="color-black-1 text-xl font-semibold color-light-black mb-5">KYC your own wallet address</div>
                    <div class="text-md color-light-black">Provactive advances are happening in the Ethereum Naming Services and Sign in with Ethereum service. PhotoChromic KYCs your chosen wallet to validation who you choose to expose your information to, and for how long.</div>
                  </div>
                </div>
                <div class="flex items-start wow fadeInUp" data-wow-delay="0.4s">
                  <img class="flex-shrink-0 mr-5" src="../assets/images/svg/foundation_icon_4.svg" alt="">
                  <div>
                    <div class="color-black-1 text-xl font-semibold color-light-black mb-5">Identity and Verification (IDV)</div>
                    <div class="text-md color-light-black">Something you know (your PIN), something you have (your credit card) and something you are (a fingerprint or unique selfie) used together can confirm that you’re live, and in fact the right person engaging with an interface with the identity mapped to that person.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TheContactForm></TheContactForm>
      </section>
    </TheContent>
    <TheFooter></TheFooter>
  </TheLayout>
</template>
<script>
import TheLayout from "@/components/TheLayout";
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import TheContent from "@/components/TheContent";
import { onMounted, inject, ref, defineAsyncComponent } from "vue";
import WOW from "wow.js";
import connection from '../utils/crypto/connection'
import TheContactForm from "@/components/TheContactForm";

const emitter = require('tiny-emitter/instance');

const HeaderConnect = defineAsyncComponent(() => import('@/components/HeaderConnect'))
const HeaderJoin = defineAsyncComponent(() => import('@/components/HeaderJoin'))
const HeaderShare = defineAsyncComponent(() => import('@/components/HeaderShare'))
const HeaderMint = defineAsyncComponent(() => import('@/components/HeaderMint'))

export default {
  components: {
    HeaderMint,
    TheContactForm,
    TheContent,
    TheFooter,
    TheHeader,
    TheLayout,
    HeaderConnect,
    HeaderJoin,
    HeaderShare
  },
  props: {},
  setup()
  {

    const router = inject('router')
    const triggerConnect = inject('triggerConnect')
    const headerState = inject('headerState', 'mint')
    const hasMetaMask = ref(false)

    async function isConnected()
    {
      //Is connected?
      let connectedAccount = await connection.getAccount();
      if (connectedAccount) {
        emitter.emit('fire-alert', 'Connected', 'success')
        return router.push({ name: 'step-2' })
      }
    }

    async function connect()
    {
      await triggerConnect()
      await isConnected();
    }


    onMounted(async () => {
      let wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true,
        scrollContainer: null,
      });
      wow.init();

      hasMetaMask.value = await connection.checkMetamask()

    })

    return { connect, hasMetaMask, headerState }
  }


}
</script>
<style scoped>
ol.how-to-mint {
  @apply list-decimal;
}

ol.how-to-mint li {
  @apply p-2;
}
</style>