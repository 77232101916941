<template>
  <TheLayout>
    <TheHeader>
      <div class="mt-40">
        <AppLoader class="text-white" size-class="h-20 w-20"></AppLoader>
      </div>
    </TheHeader>
  </TheLayout>
</template>
<script>
import TheLayout from "@/components/TheLayout.vue";
import TheHeader from "@/components/TheHeader.vue";
import { inject, onMounted } from "vue";
import AppLoader from "@/components/AppLoader.vue";
import WaitList from "@/services/api/WaitList";

export default {
  components: { AppLoader, TheHeader, TheLayout },
  props: {},
  setup()
  {

    const route = inject('route')
    const router = inject('router')
    const updateVia = inject('updateVia')
    const path = route.path;

    onMounted(async () => {


      let refCode = path.substring(1);
      await WaitList.getPingVia(refCode)
      await updateVia(refCode)
      return router.push('/')

    })


  }
}
</script>