<template>
  <TheLayout>
    <TheHeader>
      <div class="bg-danger-50 bg-opacity-75 mt-24 shadow sm:rounded-r-lg  flex flex-col items-center justify-center py-3 border-l-4 border-danger-600 text-danger-600 p-8">
        <ExclamationIcon class="w-8 h-8 mr-2"></ExclamationIcon>
        <div class="flex items-center">
          <span class="font-ubuntu text-xl mb-2">{{ message }}</span>
        </div>
        <a :href="route.fullPath" class="btn btn-primary">Refresh page</a>
      </div>
    </TheHeader>
  </TheLayout>
</template>
<script>
import { ExclamationIcon } from "@heroicons/vue/outline";
import { inject } from "vue";
import TheHeader from "@/components/TheHeader";
import TheLayout from "@/components/TheLayout";

export default {
  components: { TheLayout, TheHeader, ExclamationIcon },
  props: {
    message: {
      default: 'Unexpected Error'
    }
  },
  setup()
  {

    const route = inject('route')

    return { route }

  }

}
</script>