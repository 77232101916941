<template>
  <div v-if="accountDisplay">
    <button type="button" class="very-special-btn font-ubuntu" @click="showDisconnectMessage">
      <div class="flex flex-col items-center">
        <span class="leading-4">Wallet <span class="font-bold text-primary-300">Connected</span></span>
        <span class="leading-5 font-bold font-xs text-white font-mono" v-if="accountDisplay">{{ accountDisplay }}</span>
      </div>
    </button>
  </div>
  <div v-if="!accountDisplay">
    <button type="button" class="very-special-btn font-ubuntu" @click="triggerConnect">
      <div class="flex flex-col items-center">
        <span class="leading-4">Wallet <span class="font-bold text-gray-700">Connected</span></span>
        <span class="leading-5 font-light font-xs text-gray-700">none</span>
      </div>
    </button>
  </div>
</template>
<script>
import { inject, onMounted, onUpdated, ref } from "vue";

const emitter = require('tiny-emitter/instance');

export default {
  props: {},
  setup()
  {
    const memberState = inject('memberState')
    const accountDisplay = ref(null)
    const triggerConnect = inject('triggerConnect')

    const checkConnected = () => {
      if (memberState.connectedAccount !== null) {
        accountDisplay.value = memberState.connectedAccount.short
      }
    }

    const showDisconnectMessage = () => {
      emitter.emit('fire-alert', 'Looking to disconnect or switch account?', 'info', 'You can administer account switching and disconnecting directly from MetaMask', 18000)
    }

    onMounted(() => {
      checkConnected()

    })

    onUpdated(() => {
      checkConnected()
    })

    return { accountDisplay, showDisconnectMessage, triggerConnect }
  }
}
</script>