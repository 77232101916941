<template>
  <div v-if="pageLoading">
    <PageLoading></PageLoading>
  </div>
  <div v-if="!pageLoading">
    <div v-if="hasError">
      <AppError :message="hasError"></AppError>
    </div>
    <div v-if="!hasError">
      <router-view></router-view>
    </div>
  </div>
  <TheCookieNotification></TheCookieNotification>
  <AppAlert @show="(showVal) => alertMessage.show	= showVal" :type="alertMessage.type" :details="alertMessage.details" :show="alertMessage.show">{{ alertMessage.title }}</AppAlert>
</template>
<script>
import { onMounted, provide, ref, defineAsyncComponent, reactive } from "vue";
import { useRouter, useRoute } from 'vue-router'
import connection from "./utils/crypto/connection";
import PageLoading from "@/views/PageLoading";
import WaitList from "@/services/api/WaitList";
import Tokens from "@/services/api/Tokens";
import AppError from "@/components/AppError";
import TheCookieNotification from "@/components/TheCookieNotification";


const AppAlert = defineAsyncComponent(() =>
    import('./components/AppAlert.vue')
)

const emitter = require('tiny-emitter/instance');

export default {

  name: 'App',
  components: { TheCookieNotification, AppError, PageLoading, AppAlert },
  setup()
  {


    const pageLoading = ref(true)
    const hasError = ref(false)
    const headerState = ref('mint')
    const router = useRouter()
    const route = useRoute()


    const memberState = reactive({
      via: null,
      connectedAccount: null,
      accessToken: null,
      setEmail: null,
      hasEmail: false,
      hasConfirmedEmail: false,
      isValidated: false,
      shareCode: null,
      shareViews: 0,
      queue: null,
    })

    provide('router', router)
    provide('route', route)
    provide('memberState', memberState)
    provide('headerState', headerState)

    const resetMemberState = () => {
      memberState.connectedAccount = null
      memberState.accessToken = null
      memberState.setEmail = null
      memberState.hasEmail = false
      memberState.hasConfirmedEmail = false
      memberState.isValidated = false
      memberState.shareCode = null
      memberState.shareViews = 0
      memberState.queue = null
    }

    const checkVia = async () => {
      let currentVia = memberState.via
      let storageVia = localStorage.getItem('via')
      if (currentVia !== storageVia) {
        memberState.via = storageVia;
        await updateVia(storageVia)
      }
    }

    const updateVia = async (via) => {
      memberState.via = via;
      localStorage.setItem('via', via)
    }

    const checkConnectedAccount = async () => {

      let is_connected = await connection.connected()
      if (!is_connected) {
        //reset all
        resetMemberState()
        return false
      }
      memberState.connectedAccount = await connection.getAccount();
      await setAccessToken(memberState.connectedAccount.full);
      return true
    }

    const setAccessToken = async (account) => {
      let storageToken = localStorage.getItem('session[' + account + ']')
      if (storageToken) {
        memberState.accessToken = storageToken
        return true
      } else {
        //Create token
        //Request key
        let session = await Tokens.initSession(account)
        if (!session.success) {
          hasError.value = 'Unexpected API error. Please refresh and try again'
          return false
        }
        let token = session.data.sessionId;
        memberState.accessToken = token
        localStorage.setItem('session[' + account + ']', token)
        return true
      }
    }

    const checkMemberStatus = async () => {
      if (memberState.accessToken) {
        let fetchMemberStatus = await WaitList.status(memberState.accessToken, memberState.via);
        if (!fetchMemberStatus.success) {
          hasError.value = 'Unexpected API error on member look up. Please refresh and try again'
          localStorage.removeItem('session[' + memberState.connectedAccount.full + ']')
          return false
        }
        memberState.setEmail = fetchMemberStatus.data.email
        memberState.hasEmail = !fetchMemberStatus.data.hasEmail
        memberState.hasConfirmedEmail = !fetchMemberStatus.data.hasConfirmedEmail
        memberState.isValidated = fetchMemberStatus.data.validated
        memberState.shareCode = fetchMemberStatus.data.referralCode
        memberState.shareViews = fetchMemberStatus.data.shares
        memberState.queue = fetchMemberStatus.data.queueFormatted

        return true
      }
      hasError.value = 'Unexpected error on member look up. Please refresh and try again'
      return false

    }

    const updateState = async () => {

      // headerState.value = 'mint'

      await checkVia();
      let isConnected = await checkConnectedAccount();

      if (!isConnected) {
        pageLoading.value = false
        // headerState.value = 'connect'
        headerState.value = 'mint'
        return
      }
      await checkMemberStatus()

      pageLoading.value = false
      if (memberState.isValidated) {
        // headerState.value = 'share'
        headerState.value = 'mint'
        return
      }
      // headerState.value = 'join'
      headerState.value = 'mint'

    }

    async function metaMaskListeners()
    {
      window.ethereum.on('accountsChanged', async () => {
        pageLoading.value = true
        await updateState()
      });

    }

    const triggerConnect = async () => {
      return await connection.connect();
    }

    //Provided methods -------------------
    provide('triggerConnect', () => {
      triggerConnect()
    })

    provide('updateVia', (via) => {
      updateVia(via)
    })
    provide('updateState', () => {
      updateState()
    })

    onMounted(async () => {
      await updateState()
      await metaMaskListeners()
    })

    //Alerts ==================================================
    //TODO isolate this to it's own component

    const alertMessage = reactive({
      show: false,
      title: null,
      details: null,
      type: null,
    })

    const triggerAlert = async (title, type, details, waitTime) => {
      alertMessage.show = true
      alertMessage.title = title
      alertMessage.type = type
      alertMessage.details = details

      await connection.wait(waitTime)
      alertMessage.show = false
    }

    emitter.on('fire-alert', async function(title, type, details = null, waitTime = 3000){
      await triggerAlert(title, type, details, waitTime)
    });

    return { pageLoading, alertMessage, hasError }

  }
}
</script>