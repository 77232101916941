<template>
  <div>
    <svg :class="dimClass" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_854_60)">
        <path d="M29.5965 0C29.4954 0 29.3944 0 29.2429 0.0504032L0.606559 10.9375C-0.201522 11.2399 -0.201522 12.3992 0.657064 12.6512L7.32373 14.869L11.3136 22.8831C11.3641 23.0343 11.5157 23.1351 11.7177 23.1351C11.8187 23.1351 11.9197 23.0847 11.9702 23.0343L15.7076 19.8589L11.5156 16.6835L11.8692 20.8669L8.83888 14.6169L24.849 4.98992L11.5156 16.7339L21.9197 24.5968C22.2732 24.8488 22.6773 25 23.0813 25C23.8894 25 24.6975 24.4456 24.8995 23.5887L30.5056 1.15927C30.6571 0.554435 30.2025 0 29.5965 0Z" fill="currentColor"/>
      </g>
      <defs>
        <clipPath id="clip0_854_60">
          <rect :class="dimClass" fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    dimClass: {
      type: String,
      default: 'h-6 w-6'
    }
  },
  setup()
  {

  }
}
</script>