<template>
  <div class='flex flex-col items-center'>
    <span class='text-current'><slot></slot></span>
    <div class="flex items-center justify-center ">
      <div class="border-b-2 border-current rounded-full animate-spin" :class="sizeClass"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sizeClass: {
      default: 'w-12 h-12'
    }
  },
  setup()
  {

  }
}
</script>