import { createApp } from 'vue'
import App from './App.vue'
import './assets/scss/app.scss'
import './assets/dependencies/wow/css/animate.css'
import axios from 'axios'

axios.defaults.baseURL = 'https://api.photochromic.io/v2'
// axios.defaults.baseURL = 'http://api.photochromic.loc/v2'

import router from './router/routes.js'

const app = createApp(App)
app.use(router)
app.mount('#app')
