import axios from 'axios'

let result = {
    success: false,
    code: 200,
    meta: 'OK',
    data: {}
};

export default {

    status(token, via)
    {
        return axios.get('/status', { params: { token, via } })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },

    getPingVia(slug)
    {
        return axios.get('/share/' + slug)
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },


    postEmail(token, email, marketing, minting)
    {

        return axios.post('/wait-list/save-email', { email, marketing, minting }, { params: { token } })
            .then(response => {

                let res = response.data

                result.success = res.success
                result.meta = res.msg
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },
    postValidateCode(token, code)
    {

        return axios.post('/wait-list/validate-email', { code }, { params: { token } })
            .then(response => {
                result.success = true
                result.code = response.status
                result.data = response.data
                return result;

            }).catch(err => {

                result.code = err.response.status
                if (err.response.data.message) {
                    result.meta = err.response.data.message
                } else {
                    result.meta = err.response.statusText
                }

                return result
            });
    },

}
