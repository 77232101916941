<template>
  <div>
    <svg :class="dimClass" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M0 21.2364C2.6601 22.9818 5.91133 24 9.45813 24C20.8374 24 27.3399 14.5455 26.8966 5.96364C28.0788 5.09091 29.1133 4.07273 30 2.90909C28.9655 3.34545 27.7833 3.78182 26.4532 3.92727C27.7833 3.2 28.67 2.03636 29.1133 0.581818C27.931 1.30909 26.601 1.74545 25.2709 2.03636C24.0887 0.727273 22.4631 0 20.6897 0C16.8473 0 13.8916 3.63636 14.7783 7.41818C9.60591 7.12727 5.17241 4.8 2.06897 1.16364C0.44335 3.78182 1.18227 7.41818 3.99015 9.16364C2.95567 9.16364 2.06897 8.87273 1.18227 8.43636C1.18227 11.2 3.10345 13.8182 6.05911 14.4C5.17241 14.6909 4.28571 14.6909 3.25123 14.5455C3.99015 17.0182 6.35468 18.7636 9.01478 18.7636C6.50246 20.8 3.25123 21.6727 0 21.2364Z"/>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    dimClass: {
      type: String,
      default: 'h-5 w-5'
    }
  },
  setup()
  {

  }
}
</script>