<template>
  <TheLayout>
    <TheHeader>
      <div class="flex flex-col justify-center text-white md:mt-24">
        <AppLoader size-class="w-20 h-20" class="text-4xl font-ubuntu font-light">Loading</AppLoader>
      </div>
    </TheHeader>
  </TheLayout>
</template>
<script>
import TheLayout from "@/components/TheLayout";
import TheHeader from "@/components/TheHeader";
import AppLoader from "@/components/AppLoader";

export default {
  components: { AppLoader, TheHeader, TheLayout },
  props: {},
  setup()
  {

  }

}
</script>